<template>
  <div class="index">
    <div class="topBg" />
    <div class="top">
      <div class="box">
        <div class="tab">
          <router-link to="/homework">
            <div :class="{ select: this.$route.path.includes('homework') }">作业</div>
          </router-link>
          <router-link to="/test">
            <div :class="{ select: this.$route.path.includes('test') }">考试题</div>
          </router-link>
          <router-link to="/exercises">
            <div :class="{ select:['/exercisesDetail','/exercises'].includes(this.$route.path) }">专项练习</div>
          </router-link>
          <router-link to="/wordLearning">
            <div :class="{ select: this.$route.path.includes('wordLearning') }">单词学习</div>
          </router-link>
          <router-link to="/wordDictation">
            <div :class="{ select: ['/wordDictation','/viewTestPaper'].includes(this.$route.path) }">单词听写</div>
          </router-link>
        </div>
        <div v-if="['/exercises'].includes(this.$route.path)" class="search">
          <el-select
            v-model="questionType"
            clearable
            placeholder="选择题型"
            @change="onSearch"
            @clear="onSearch"
          >
            <el-option
              v-for="item in ea_question_type"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-select
            v-model="type"
            clearable
            placeholder="选择类型"
            @change="onSearch"
            @clear="onSearch"
          >
            <el-option
              v-for="item in ea_question_category"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-select
            v-model="level"
            clearable
            placeholder="选择难度"
            @change="onSearch"
            @clear="onSearch"
          >
            <el-option
              v-for="item in ea_question_difficulty"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-select
            v-model="status"
            clearable
            placeholder="选择完成情况"
            @change="onSearch"
            @clear="onSearch"
          >
            <el-option
              v-for="item in statusArr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
    </div>
    <router-view ref="exercises" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Index',
  computed: {
    ...mapState({
      ea_question_type: state => state.dict.dictTree.ea_question_type['arr'],
      ea_question_category: state => state.dict.dictTree.ea_question_category['arr'],
      ea_question_difficulty: state => state.dict.dictTree.ea_question_difficulty['arr']
    })
  },
  data() {
    return {
      type: '',
      questionType: '',
      level: '',
      status: '',
      statusArr: [
        {
          label: '未完成',
          value: '0'
        },
        {
          label: '已完成',
          value: '1'
        }
      ]
    }
  },
  methods: {
    onSearch() {
      const obj = {
        questionType: this.questionType,
        type: this.type,
        level: this.level,
        status: this.status
      }
      this.$refs.exercises.onLoad(obj)
    }
  }
}
</script>

<style scoped lang="less">
.select {
  color: #000000 !important;
  background-color: #FFFFFF;
  text-align: center;
  border-radius: 25px;
  //hr{
  //    margin: 8px auto !important;
  //    width: 35px !important;
  //    display: block !important;
  //}
}

.index {
  margin-top: 80px;

  .topBg {
    background-color: #4A6BD6;
    width: 100%;
    height: 100%;
  }

  .top {
    min-width: 1290px;
    width: 100%;
    height: 80px;
    background: #edf1f7;

    .box {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .tab {
      display: flex;
      //justify-content: center;
      div {
        //height: 30px;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #9b9b9b;
        padding: 8px 22px;
        //line-height: 18px;

      }

      div:hover {
        color: #4392fd;
        //hr{
        //    margin: 8px auto;
        //    width: 35px;
        //    display: block;
        //}
      }
    }

    .search {
      text-align: left;
      margin: 17px 0;

      .el-select {
        width: 130px;
        margin-left: 10px;
        background: #FFFFFF;

        ::v-deep.el-input__inner {
          background: #ffffff;
          border: 1px solid #BFC4CD;
        }

        ::v-deep.el-input__inner::placeholder {
          color: #999999;
        }
      }
    }
  }
}
</style>
